.about {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
}

.about .left {
  margin: 4rem;
  padding: 0.1rem;
  max-width: 45rem;
}

.about .left p {
  margin: 1.2rem 0;
  text-align: justify;
}

.about .right {
  max-width: 700px;
  padding-right: 1rem;
}

.button-container {
  display: flex;
  gap: 1rem; /* Adjust the gap as needed */
}

@media screen and (max-width: 950px) {
  .logoPic {
    width: 350px;
    height: 350px;
  }
  .about .left p {
    text-align: center;
  }

  .about .left .button-container {
    display: flex;
    justify-content: center;
  }
}
