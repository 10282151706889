.project-heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.project-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the button is at the bottom */
  height: 100%; /* Make sure the card takes the full height */
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card img {
  width: 100%;
}

.project-title {
  font-size: 1.9rem;
  color: #fff;
  padding: 1rem 0;
}

.pro-details p {
  font-size: 1.1rem;
  text-align: justify;
  flex-grow: 1; /* Takes up remaining space */
}

.pro-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.pro-btns .btn {
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 950px) {
  .project-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}
